<template>
  <v-sheet class="item" id="item">
    <v-row>
      <!-- <pre>{{ productList }}</pre> -->
      <v-col md="6" class="my-auto">
        <h1 class="custom-header-blue-text m-0 text-truncate">{{ pageTitle() }}</h1>
      </v-col>
      <v-col md="6" class="text-right">
        <template  v-if="item.status =='Draft'">
            <v-menu
              transition="slide-y-transition"
              bottom
              content-class="custom-menu-list"
              offset-y
            >
            
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  v-bind="attrs"
                  v-on="on"
                >
                  More... <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-for="(more, index) in customerMoreAction">
                  <v-list-item
                    link
                    v-on:click="approve(item.id)"
                    :key="index"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                      <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="font-weight-500 font-size-14">{{
                      more.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </template>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Back
        </v-btn>
      </v-col>
      <v-col md="12">
        <v-container fluid >
                <table width="100%" class="detail-table">
                  <tr>
                    <th width="200">Date</th>
                    <td>
                      {{ formatedateTimes(item.date) }}
                     
                    </td>
                    <th width="200">Reference</th>
                    <td>
                      <ValueTemplate
                        v-model="item.refrence"
                        title="Reference"
                      ></ValueTemplate>
                    </td>
                  </tr>
                  <tr>
                    <th width="200">Type</th>
                    <td>
                      <span v-if=" item.document_Type=='stock_transfer'"> Stock Transfer
</span>
<span v-else > Stock Adjustment
</span>
                    </td>
                   <th width="200">Status</th>
                    <td>
                      <v-chip v-if="item.status=='Draft'" color="cyan" class="mr-2 white--text px-2" small
                                            >{{ item.status }}</v-chip>
                     <v-chip v-if="item.status!='Draft'" color="green" class="mr-2 white--text px-2" small
                                            >{{ item.status }}</v-chip>
                    </td>
                  </tr>
                  <tr v-if="item.document_Type=='stock_transfer'">
                    <th width="200">From Warehouse</th>
                    <td>
                      <span > {{ item.from_warehouse }}
</span>

                    </td>
                   <th width="200">To Warehouse</th>
                    <td>
                    {{ item.to_warehouse }} 
                    </td>
                  </tr>
                  
                 
                
                </table>
              </v-container>
       
      </v-col>
    <v-col md="12" class="" v-if="productList?.length">
      <v-simple-table
        fixed-header
        style="height: calc(100vh - 280px)"
        class="bt-table product-simple-table"
      >
        <template v-slot:default>
          <thead style="border: 2px solid #f4f4f4; position: relative; top: 0">
            <tr>
              <th
                v-for="(supplier_h, index) in supplierTableHeaders"
                :key="'$_' + supplier_h.title + '_' + index + 1 + supplier_h.heading"
                :style="{ maxWidth: `${supplier_h.width}px` }"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center" v-on="on" v-bind="attrs">
                      <template v-if="supplier_h.heading === 'primary_sales_price'">
                        <v-icon color="blue">mdi-circle</v-icon>
                      </template>
                      <template v-if="supplier_h.heading === 'secondary_purchase_price'">
                        <v-icon color="red">mdi-circle</v-icon>
                      </template>
                      <p class="text-truncate mb-0">{{ supplier_h.title }}</p>
                    </div>
                  </template>
                  <span class="h5 text-center">{{ supplier_h.title }}</span>
                </v-tooltip>
              </th>
            </tr>
          </thead>
          <tbody v-if="!pageLoading">
            <!-- <pre>{{ productList }}</pre> -->
          
            <template v-if="productList?.length">
              <tr
                :class="index % 1 === 0 && '#fff'"
                v-for="(row, key, index) in productList"
                class="product-listing-tr"
                :key="'k_' + index"
              >
                <td
                  style="border-right: none !important"
                  :width="data_headings.width"
                  v-for="(data_headings, idx) in supplierTableHeaders"
                  :key="data_headings.heading + '_' + idx + '_' + idx * 5"
                  class="px-2"
                >
                  <div
                    class="d-flex justify-center align-center"
                    v-if="data_headings.heading === 'supplier_status'"
                  >
                    <v-switch
                      color="green"
                      :value="false"
                      :ripple="false"
                      hide-details
                      inset
                      disabled="true"
                      class="mt-0"
                      v-model="row[data_headings.heading]"
                    ></v-switch>
                  </div>
                  <div
                    class="d-flex justify-center align-center"
                    v-else-if="
                      data_headings.heading === 'primary_sales_price' ||
                      data_headings.heading === 'secondary_purchase_price'
                    "
                  >
                    {{ formatMoneyVal(row[data_headings.heading]) }}
                  </div>
                  <p v-else class="mb-0 h5 px-6">
                    <template v-if="data_headings.heading == 'current_primary'">
                      <div class="d-flex align-center">
                                          <v-chip color="blue" class="mr-2 white--text px-2" small
                                            >P</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{ row.current_primary }}</span>
                                          <span class="font-weight-bold ml-2">{{ row.product.primary_sales_uom }}</span>
                                        </div>
                                        <div class="d-flex align-center pt-2">
                                          <v-chip color="red" class="mr-2  white--text px-2" small
                                            >S</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{ row.current_secondary }}</span>
                                          <span class="font-weight-bold ml-2">{{ row.product.secondary_purchase_uom }}</span>
                                        </div>
                      <!-- {{ row[data_headings.heading] + " " + row["primary_sales_uom"] }} -->
                    </template>
                    <template v-else-if="data_headings.heading == 'type'">
                      <div v-if="row.type">
                        <v-chip v-if="row.type=='addition'"  color="green" class="mr-2 white--text px-2 text-capitalize" small
                                            >                      {{ row[data_headings.heading]}}
</v-chip
                                          >
                                          <v-chip v-else  color="red" class="mr-2 white--text px-2 text-capitalize" small
                                            >                      {{ row[data_headings.heading]}}
</v-chip
                                          >
                      </div>
                      <div v-else> - </div>
                     
                    </template>
                    <template v-else-if="data_headings.heading == 'product'">
                      <div v-if="row.product?.barcode">
                        
                                          <v-chip small outlined label color="blue">{{ row.product?.barcode }}</v-chip>
                      </div>
                      <div v-else> - </div>
                     
                    </template>
                    <template v-else-if="data_headings.heading == 'quantity'">
                      <div class="d-flex align-center">
                                          <v-chip color="blue" class="mr-2 white--text px-2" small
                                            >P</v-chip
                                          >
                                          <span v-if="row.quantity_primary" class="font-weight-bold ml-2">{{ row.quantity_primary }}</span>
                                          <span v-else class="font-weight-bold ml-2">0</span>
                                          <span class="font-weight-bold ml-2">{{ row.product.primary_sales_uom }}</span>
                                        </div>
                                        <div class="d-flex align-center pt-2">
                                          <v-chip color="red" class="mr-2 white--text px-2" small
                                            >S</v-chip
                                          >
                                          <span v-if="row.quantity_secondary" class="font-weight-bold ml-2">{{ row.quantity_secondary }}</span>
                                          <span v-else class="font-weight-bold ml-2">0</span>

                                          <span class="font-weight-bold ml-2">{{ row.product.secondary_purchase_uom }}</span>
                                        </div>
                    </template>
                    <template v-else-if="data_headings.heading == 'serial_no'">
                      {{ row.batch}}
                    </template>
                    <template v-else-if="data_headings.heading == 'available_secondary'">
                      <div v-if="row.type">
                        <div class="d-flex align-center">
                                          <v-chip color="blue" class="mr-2 white--text px-2" small
                                            >P</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{ row.stock_available_psu }}</span>
                                          <span class="font-weight-bold ml-2">{{ row.product.primary_sales_uom }}</span>
                                        </div>
                                        <div class="d-flex align-center pt-2">
                                          <v-chip color="red" class="mr-2 white--text px-2" small
                                            >S</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{ row.stock_available_spu }}</span>
                                          <span class="font-weight-bold ml-2">{{ row.product.secondary_purchase_uom }}</span>
                                        </div>
                      </div>
                      <div v-else> - </div>
                    
                    </template>
                    
                    <span v-if="row[data_headings.title.toLowerCase() + '_uom']">
                      / {{ row[data_headings.title.toLowerCase() + "_uom"] }}
                    </span>
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
      <v-layout v-if="productList?.length" class="light-border-top product-table-bottom mt-4">
          <v-flex md6 align-self-center>
            <label class="btx-label p-4">
              Showing {{ showingFrom }} to {{ showingTo }} of {{ total }} entries</label>
          </v-flex>

          <v-flex md6 align-self-center>
            <v-pagination v-model="currentPage" :length="totalPages" :total-visible="5"
              color="blue darken-4"></v-pagination>
          </v-flex>
        </v-layout>
    </v-col>
    <v-col v-else md="12" class="text-center mt-4">
      <p class="m-0 text-center black--text text-h6">
        <img
          width="30"
          :src="$assetURL('media/error/empty.png')"
          class="row-not-found-image mr-4"
        />
        Uhh... There are no data available at the moment.
      </p>
    </v-col>
    </v-row>
    <DeleteTemplate
      type="item"
      :delete-text="deleteText"
      delete-note="All transactions of this item will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="goBack()"
    ></DeleteTemplate>
  </v-sheet>
</template>

<script>
import { toSafeInteger, find } from "lodash";
// import ImageTemplate from "@/view/components/Image";
import ValueTemplate from "@/view/components/ValueTemplate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ADJUSTMENT,ADJUSTMENT_PRODUCT } from "@/core/lib/item.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate"; 
 import moment from "moment-timezone";
 import { PUT } from "@/core/services/store/request.module";

 import {  SET_MESSAGE } from "@/core/services/store/common.module";


export default {
  name: "item-detail",
  title: "Detail Item",
  data() {
    return {
      productList:[],
      supplierTableHeaders: [
        { title: "Product #", heading: "product", width: 150, uom: "" },

        { title: "Current Stock", heading: "current_primary", width: 150, uom: "" },
        { title: "Type", heading: "type", width: 150, uom: "" },
        { title: "Serial No", heading: "serial_no", width: 150, uom: "" },
        { title: "Quantity", heading: "quantity", width: 150, uom: "" },
        { title: "Final Quantity", heading: "available_secondary", width: 150, uom: "" },
      ],
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      customerMoreAction: [
        {
          title: "Mark as Approve",
          icon: "mdi-check-all",
          action: "approve",
        },
       
      ],
      images: [],
      itemId: null,
      item: {
        barcode: null,
        name_en: null,
        name_ch: null,
        reward: null,
        reward_point: null,
        sale: null,
        sale_amount: null,
        sale_start: null,
        sale_end: null,
        description: null,
        cuisine: null,
        category: null,
        sub_category: null,
        add_on_category: null,
        image: [],
        amount: null,
      },
    };
  },
  methods: {
    approve(id){
      console.log(id,'id')
      this.$store
        .dispatch(PUT, {
          url: `adjustment-order-approve`,
          data: {
            id: id,
          },
        })
        .then((data) => {
          console.log(data)
          this.$router.replace({
            name: "Adjustments",
            // params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Ajustment Approved Successfully." },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });

    },
    formatedateTime(param) {
      return moment(param).format("DD/MM/YY ");
    },
    formatedateTimes(param) {
        return moment(param).format("DD/MM/YYYY");
      },
    deleteConfirm() {
      this.deleteText = this.item.barcode;
      this.deleteEndpoint = `item/${this.itemId}`;
      this.deleteDialog = true;
    },
    pageTitle() {
      
      return "Adjustment";
    },
    primaryImage() {
      const primary = find(this.images, { primary: 1 });
      if (!primary) {
        if (!this.images.length) {
          return null;
        }
        this.images[0].primary = 1;
        return this.images[0].url;
      }
      return primary.url;
    },
    async getItem() {
      try {
        const item = await GET_ADJUSTMENT(this.itemId);
        this.item = item
        this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Item", disabled: true },
          { text: "Detail", disabled: true },
          { text: item.barcode, disabled: true },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    async getProducts() {
      const _this = this;
      this.pageLoading = true;
      try {
        const { rows, current_page, showingFrom, showingTo, totalrows, totalPages, status_list, activated_quantity, deactivated_quantity, total_stocks, total_inactive, total_active,total_data } =
          await ADJUSTMENT_PRODUCT(this.itemId);
        this.productList = rows;
        console.log(this.productList,'productList');
        this.total = totalrows;

        this.status_list = status_list;
        this.totalPages = totalPages;
        this.total_data=total_data;
        this.total_active = total_active;
        this.total_inactive = total_inactive;

        this.currentPage = current_page;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.status_list = status_list;
        this.totalrows = totalrows;
        this.activated_quantity = activated_quantity;
        this.deactivated_quantity = deactivated_quantity;
        this.total_stocks = total_stocks;

      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.loadPagination = true;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Item", disabled: true },
      { text: "Detail", disabled: true },
    ]);

    const { id } = this.$route.params;
    if (id) {
      this.itemId = toSafeInteger(id);
      this.getItem();
      this.getProducts();
      console.log(this.$route.params.id,'this.$route.params')
    } else {
      this.goBack();
    }
  },
  components: {
    DeleteTemplate,
    // ImageTemplate,
    ValueTemplate,
  },
  computed: {
    itemTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.itemTab) {
          this.$router.replace({ query });
        }
      },
      get() {
        return this.$route.query.tab || "overview";
      },
    },
  },
};
</script>
